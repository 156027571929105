import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/about',
    name: 'about',
    redirect: '/about/profile',
    component: () => import('@/views/about'),
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/about/profile')
      },
      {
        path: 'video',
        name: 'video',
        component: () => import('@/views/about/video')
      },
      {
        path: 'honor',
        name: 'honor',
        component: () => import('@/views/about/honor')
      },
      {
        path: 'culture',
        name: 'culture',
        component: () => import('@/views/about/culture')
      },
      {
        path: 'recruitment',
        name: 'recruitment',
        component: () => import('@/views/about/recruitment')
      }]
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/product'),
  },
  {
    path: '/productDetail/:id',
    name: 'productDetail',
    component:() => import('@/views/product/detail')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact')
  },
  {
    path: '/technology',
    name: 'technology',
    redirect: '/technology/r&d',
    component: () => import('@/views/technology'),
    children: [
      {
        path: 'r&d',
        name: 'r&d',
        component: () => import('@/views/technology/R&D')
      },
      {
        path: 'control',
        name: 'control',
        component: () => import('@/views/technology/control')
      },
      {
        path: 'laboratory',
        name: 'laboratory',
        component: () => import('@/views/technology/laboratory')
      }
    ]
  },
  {
    path: '/news',
    name: 'news',
    redirect: '/news/new',
    component: () => import('@/views/news'),
    children: [
      {
        path: 'new',
        name: 'new',
        component: () => import('@/views/news/new')
      },
      {
        path: 'report',
        name: 'report',
        component: () => import('@/views/news/report')
      },
      {
        path: 'exhibition',
        name: 'exhibition',
        component: () => import('@/views/news/exhibition')
      }
    ]
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    component:() => import('@/views/news/detail')
  },
  {
    path: '/exhibitionDetail/:id',
    name: 'exhibitionDetail',
    component:() => import('@/views/news/exhibition/detail')
  },
  {
    path: '/reportDetail/:id',
    name: 'reportDetail',
    component:() => import('@/views/news/report/detail')
  },
  {
    path: '/require',
    name: 'require',
    component: () => import('@/views/require'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    //x代表横坐标，y代表纵坐标
    return{x:0,y:0}
  }
})

export default router
