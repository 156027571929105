import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bannerList:[]
  },
  getters: {
    getBannerList: state => state.bannerList
  },
  mutations: {
    setBanner(state,res){
      state.bannerList=res
    }
  },
  actions: {
    async getBanner({commit},requestMethod){
      try {
        const res =await requestMethod('banner/getPaginate')
      if (res.data.code===0) {
        commit('setBanner',res.data.data.data)
      }
      } catch (error) {
        if (error.code==='ECONNABORTED') {
          console.error('请求超时',error.message);
        }else{
          console.error('请求出错',error);
        }
      }
    }
  },
  modules: {
  }
})
